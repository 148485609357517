@import '~amo/css/styles';

/* stylelint-disable selector-class-pattern */

// image-height has to be 20px smaller to prevent screenshot overflow.
// See: https://github.com/mozilla/addons-frontend/issues/4887
$screenshot-height: 220px;
$image-height: $screenshot-height - 20px;
$screenshot-width: 320px;

.ScreenShots {
  .pswp__caption__center {
    text-align: center;
  }
}

.ScreenShots-viewport {
  height: $screenshot-height;
}

.ScreenShots-list {
  display: flex;
  height: $screenshot-height;
  list-style-type: none;
  margin: 0;
  overflow-x: auto;
  overflow-y: hidden;
  padding: 0;
  width: auto;
}

.ScreenShots-image {
  cursor: pointer;
  display: inline-block;
  height: $image-height;
  margin: 0 10px;
  width: $screenshot-width;

  &:first-of-type {
    @include margin-start(0);
  }
}

// Don't stretch the images.
.ScreenShots-image,
.pswp__img {
  object-fit: contain;
}

.ScreenShots-list .pswp__caption {
  overflow-wrap: break-word;
}
